<template>
  <b-modal
    id="modal-danger"
    v-model="modalShow"
    ok-variant="danger"
    ok-title="Add"
    modal-class="modal-normal"
    centered>
    <div class="d-block text-center mb-1">
      <p class="font-weight-bold h2">
        Add Category Level {{ numLvl }}
      </p>
    </div>
    <ValidationObserver
      ref="CategoryForm"
      tag="form">
      <b-row class="align-items-end">
        <b-col>
          <TextFieldInput
            v-model="numLvl"
            :rules="{ required: true }"
            disabled
            name="Level"
            label="Level"
            trim />
        </b-col>
      </b-row>
      <b-row class="align-items-end">
        <b-col>
          <TextFieldInput
            v-model="categories.name"
            :rules="{required: true}"
            name="Category Name"
            label="Category Name"
            placeholder="Input Category Name"
            trim />
        </b-col>
      </b-row>
      <b-row class="align-items-end">
        <b-col>
          <TextFieldInput
            v-model.number="categories.resolvedDay"
            :rules="{
              required: true,
              min_value: 0,
              numeric: true
            }"
            name="Resolved Day"
            label="Resolved Day"
            placeholder="Input Resolved Day"
            trim
            @keypress="$keypressNumberNoDecimal()" />
        </b-col>
      </b-row>
      <b-row class="align-items-end">
        <b-col>
          <TextFieldInput
            v-model.number="categories.resolvedHour"
            :rules="{
              numeric: true,
              required: true,
              between: [0, 23] }"
            name="Resolved Hour"
            label="Resolved Hour"
            placeholder="Input Resolved Hour"
            trim
            @keypress="$keypressNumberNoDecimal()" />
        </b-col>
      </b-row>
      <b-row class="align-items-end">
        <b-col>
          <TextFieldInput
            v-model.number="categories.resolvedMin"
            :rules="{
              numeric: true,
              required: true,
              between: [0, 59] }"
            name="Resolved Min"
            label="Resolved Min"
            placeholder="Input Resolved Min"
            trim
            type="number"
            @keypress="$keypressNumberNoDecimal()" />
        </b-col>
      </b-row>
      <b-row class="align-items-end">
        <b-col>
          <SelectField
            v-model="categories.priority"
            :options="categoriesEnum"
            name="Priority"
            form-label="Priority"
            label-option="label"
            :rules="{
              required: true,
            }"
            return-value="value" />
        </b-col>
      </b-row>
      <b-row class="align-items-end">
        <b-col>
          <SelectField
            v-model="categories.urgency"
            :options="categoriesEnum"
            name="Urgency"
            form-label="Urgency"
            label-option="label"
            :rules="{
              required: true,
            }"
            return-value="value" />
        </b-col>
      </b-row>
      <b-row class="align-items-end">
        <b-col>
          <SelectField
            v-model="categories.impact"
            :options="categoriesEnum"
            :rules="{
              required: true,
            }"
            name="Impact"
            form-label="Impact"
            label-option="label"
            return-value="value" />
        </b-col>
      </b-row>
      <b-row class="align-items-end">
        <b-col>
          <SelectField
            v-if="numLvl === 1"
            v-model="categories.visibelGroup"
            :close-on-select="false"
            :deselect-from-dropdown="true"
            multiple
            :options="groupService"
            name="Group"
            form-label="Group"
            label-option="name"
            return-value="id"
          />
        </b-col>
      </b-row>
    </ValidationObserver>
    <template #modal-footer="{ hide }">
      <div class="mx-auto">
        <b-button
          variant="primary"
          style="margin-right: 12px"
          @click="confirmAdd()">
          Add
        </b-button>
        <b-button
          variant="outline-primary"
          @click="hide">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'

export default {
  components: {
    TextFieldInput,
    SelectField
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    items: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Modal'
    },
    numLvl: {
      type: Number,
      default: null
    },
    groupService: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      categoriesEnum: [
        { label: 'low', value: 'low' },
        { label: 'medium', value: 'medium' },
        { label: 'high', value: 'high' }
      ],
      categories: {
        level: '',
        name: '',
        parentId: 0,
        resolvedDay: '',
        resolvedHour: '',
        resolvedMin: '',
        priority: 'low',
        urgency: 'low',
        impact: 'low',
        visibelGroup: null
      }
    }
  },
  computed: {
    modalShow: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    async confirmAdd () {
      // console.log('resetForm', this.$refs.CategoryForm)
      const valid = await this.$refs.CategoryForm.validate()
      if (valid) {
        this.$emit('confirm', {
          ...this.categories,
          level: this.numLvl
        })
      }
    },
    resetForm () {
      this.categories = {
        level: '',
        name: '',
        parentId: 0,
        resolvedDay: '',
        resolvedHour: '',
        resolvedMin: '',
        priority: 'low',
        urgency: 'low',
        impact: 'low',
        visibelGroup: null
      }
      this.$refs.CategoryForm.reset()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
