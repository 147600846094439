<template>
  <b-modal
    id="modal-danger"
    v-model="modalShow"
    modal-class="modal-normal"
    centered
    hide-footer>
    <div class="d-block text-center mb-1">
      <p class="font-weight-bold h2">
        {{ statusTitle }}
      </p>
    </div>
    <div class="d-flex mb-2">
      <feather-icon
        class="mx-auto"
        size="150"
        :color="statusColor"
        :icon="statusIcon" />
    </div>
    <div class="d-block text-center font-weight-bold mb-1">
      {{ statusDescription }}
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    statusTitle: {
      type: String,
      default: ''
    },
    statusDescription: {
      type: String,
      default: ''
    },
    statusColor: {
      type: String,
      default: ''
    },
    statusIcon: {
      type: String,
      default: ''
    }
  },
  computed: {
    modalShow: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        if (!val) {
          this.$emit('discard')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
